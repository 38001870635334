var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"comments"}},[_c('div',{ref:"commentsBelow",staticClass:"commentsBelow",style:(_vm.customContainerStyle)},[(!_vm.hiddenTitle)?_c('div',{staticClass:"panel-title left"},[_vm._v(" 全部评论("+_vm._s(_vm.commentTotal)+") ")]):_vm._e(),_c('div',{staticClass:"containers"},[_vm._l((_vm.commentsArray),function(comment,index){return _c('div',{key:index,ref:`commentRef-${comment.id}`,refInFor:true,staticClass:"team-cell"},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"team-cell__bd"},[_c('div',{staticClass:"team-cell-title"},[_c('div',{staticClass:"comment-name",staticStyle:{"line-height":"36px"}},[_c('router-link',{attrs:{"to":`/userPage?userId=${comment.userId}`}},[(comment.avatar)?_c('el-popover',{attrs:{"placement":"right","width":"300","trigger":"hover"},on:{"show":function($event){return _vm.getHoverUserInfo(comment.userId)}}},[_c('div',{staticClass:"hoverUserInfo"},[_c('el-row',{attrs:{"gutter":6}},[_c('el-col',{attrs:{"span":4}},[_c('img',{staticClass:"hoverAvatar",attrs:{"src":_vm.hoverUserInfo.avatar,"alt":""}})]),_c('el-col',{attrs:{"span":20}},[_c('div',{staticClass:"hoverUserName"},[_vm._v(" "+_vm._s(_vm.hoverUserInfo.userName)+" ")]),_c('div',{staticClass:"hoverDes"},[_vm._v(" "+_vm._s(_vm.hoverUserInfo.introduction)+" ")]),(
                              !_vm.userInfo.id ||
                              _vm.hoverUserInfo.userId != _vm.userInfo.id
                            )?_c('el-button',{staticClass:"hoverConcern",attrs:{"size":"small","round":"","type":_vm.hoverUserInfo.concern ? 'info' : 'primary'},on:{"click":function($event){return _vm.focusUser(_vm.hoverUserInfo.userId)}}},[_vm._v(_vm._s(_vm.hoverUserInfo.concern ? "已关注" : "关注")+" ")]):_vm._e()],1)],1)],1),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('img',{staticClass:"userAvatar",attrs:{"src":comment.avatar},on:{"click":function($event){$event.stopPropagation();return _vm.jumpUserPage(comment.userId)}}})])]):_vm._e()],1),_c('div',{staticStyle:{"vertical-align":"top","font-size":"12px","margin-left":"6px","display":"inline-block","line-height":"20px"}},[_vm._v(" "+_vm._s(comment.username)+" ")])],1)]),_c('div',{staticStyle:{"padding-left":"60px","transform":"translateY(-26px)"}},[_c('div',{staticClass:"team-cell-desc",domProps:{"innerHTML":_vm._s(comment.visibility ? comment.content : `该评论已删除`)}}),_c('div',{staticClass:"team-cell-info"},[_c('div',{staticClass:"comment-user"},[_c('div',{staticStyle:{"font-size":"12px","margin-left":"0"}},[_vm._v(" "+_vm._s(comment.createTime)+" ")]),_c('div',{staticClass:"comment-number",on:{"click":function($event){return _vm.likeComments(comment.id)}}},[_c('i',{staticClass:"iconfont icon-dianzan",staticStyle:{"font-size":"14px"}}),_vm._v(_vm._s(comment.likeNums)+" ")]),_c('div',{staticClass:"comment-number",on:{"click":function($event){return _vm.showComment(comment)}}},[_vm._v(" 回复 ")])])]),(comment.showComment)?_c('div',{staticClass:"replys",staticStyle:{"margin-top":"12px"}},[_c('el-input',{attrs:{"placeholder":`评论${comment.username}...`},model:{value:(comment.commentValue),callback:function ($$v) {_vm.$set(comment, "commentValue", $$v)},expression:"comment.commentValue"}},[_c('template',{slot:"append"},[_c('emotions',{on:{"chooseEmotion":(emotion) =>
                          _vm.handleChildCommentEmotion(emotion, comment)}}),_c('el-button',{staticStyle:{"margin-left":"4px","border-left":"1px solid #dcdfe6","border-radius":"0px"},on:{"click":function($event){return _vm.publishChildComment(comment)}}},[_vm._v("评论")])],1)],2)],1):_vm._e(),(comment.children)?_c('div',{staticClass:"secondCommentList"},[_vm._l((comment.children.showAll ||
                  comment.children.total <= 3
                    ? comment.children.records
                    : comment.children.records.slice(0, 3)),function(childsComment,index){return _c('div',{key:index,staticClass:"childComment"},[_c('router-link',{attrs:{"to":`/userPage?userId=${childsComment.userId}`}},[_c('img',{staticClass:"avatar",attrs:{"src":childsComment.avatar,"alt":""}})]),(
                      !childsComment.toAvatar || !childsComment.toUsername
                    )?_c('div',{staticStyle:{"display":"inline-block","width":"calc(100% - 40px)"}},[_vm._v(" "+_vm._s(childsComment.username)+": "),_c('span',{staticClass:"commentContent"},[_vm._v(" "+_vm._s(childsComment.visibility ? childsComment.content : "该评论已删除")+" ")])]):_c('div',{staticStyle:{"display":"inline-block","width":"calc(100% - 40px)"}},[_vm._v(" "+_vm._s(childsComment.username)+" "),_c('i',{staticClass:"el-icon-caret-right"}),_vm._v(" "+_vm._s(childsComment.toUsername)+": "),_c('span',{staticClass:"commentContent"},[_vm._v(" "+_vm._s(childsComment.visibility ? childsComment.content : "该评论已删除")+" ")])]),_c('div',{staticClass:"team-cell-info"},[_c('div',{staticClass:"comment-user"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(childsComment.createTime))]),_c('div',{staticClass:"comment-number",on:{"click":function($event){return _vm.likeChildsComments(comment, childsComment)}}},[_c('i',{staticClass:"iconfont icon-dianzan",staticStyle:{"font-size":"14px"}}),_vm._v(_vm._s(childsComment.likeNums)+" ")]),_c('div',{staticClass:"comment-number",on:{"click":function($event){return _vm.showComment(childsComment)}}},[_vm._v(" 回复 ")])])]),(childsComment.showComment)?_c('div',{staticClass:"replys",staticStyle:{"margin-top":"12px"}},[_c('el-input',{attrs:{"placeholder":`回复${childsComment.username}...`},model:{value:(childsComment.commentValue),callback:function ($$v) {_vm.$set(childsComment, "commentValue", $$v)},expression:"childsComment.commentValue"}},[_c('template',{slot:"append"},[_c('emotions',{on:{"chooseEmotion":(emotion) =>
                              _vm.handleChildCommentEmotion(
                                emotion,
                                childsComment
                              )}}),_c('el-button',{staticStyle:{"margin-left":"4px","border-left":"1px solid #dcdfe6","border-radius":"0px"},on:{"click":function($event){return _vm.publishSecondChildComment(comment, childsComment)}}},[_vm._v("回复")])],1)],2)],1):_vm._e()],1)}),(
                    comment.children.total > 3 && !comment.children.showAll
                  )?_c('div',{staticStyle:{"padding-left":"12px"}},[_vm._v(" 共"+_vm._s(comment.children.total)+"条回复，"),_c('span',{staticStyle:{"color":"#00a1d6","cursor":"pointer"},on:{"click":function($event){comment.children.showAll = true;
                      _vm.$forceUpdate();}}},[_vm._v("点击查看")])]):_vm._e(),(
                    comment.children.total > 10 && comment.children.showAll
                  )?_c('div',{staticStyle:{"text-align":"left","padding":"12px"}},[_c('el-pagination',{staticStyle:{"padding":"0"},attrs:{"prev-text":"上一页","next-text":"下一页","current-page":comment.children.current,"total":comment.children.total,"layout":"prev, pager, next"},on:{"update:currentPage":function($event){return _vm.$set(comment.children, "current", $event)},"update:current-page":function($event){return _vm.$set(comment.children, "current", $event)},"current-change":(page) => _vm.getChildComments(comment)}})],1):_vm._e()],2):_vm._e()])])])])}),(_vm.commentsArray.length == 0)?_c('div',{staticClass:"noComments"},[_vm._v("暂无评论")]):_vm._e()],2)]),(_vm.commentsArray.length > 0)?_c('div',{staticClass:"pagination-block"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"total":_vm.total,"layout":"prev, pager, next"},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"current-change":(page) => _vm.getComments(page, true)}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }