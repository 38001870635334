// 用户
import request from '../utils/request';

// 获取用户动态主页信息
export async function getUserDetail(userId) {
    try {
        return await request({
            url: `/posts/userStatics/${userId}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 获取动态列表
export async function getTrendsList(params) {
    try {
        return await request({
            url: `/posts/userPosts`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取关注人列表
export async function getFocusList(params, userId) {
    try {
        return await request({
            url: `/concern/${userId}`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

export async function getSchoolList(params) {
    try {
        return await request({
            url: `/university/select`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

