<template>
  <!-- 评论插件 -->
  <div id="comments">
    <div
      class="commentsBelow"
      ref="commentsBelow"
      :style="customContainerStyle"
    >
      <div class="panel-title left" v-if="!hiddenTitle">
        全部评论({{ commentTotal }})
      </div>
      <div class="containers">
        <div
          class="team-cell"
          v-for="(comment, index) in commentsArray"
          :key="index"
          :ref="`commentRef-${comment.id}`"
        >
          <div class="clearfix">
            <div class="team-cell__bd">
              <div class="team-cell-title">
                <div class="comment-name" style="line-height: 36px">
                  <router-link :to="`/userPage?userId=${comment.userId}`">
                    <el-popover
                      v-if="comment.avatar"
                      placement="right"
                      width="300"
                      trigger="hover"
                      @show="getHoverUserInfo(comment.userId)"
                    >
                      <div class="hoverUserInfo">
                        <el-row :gutter="6">
                          <el-col :span="4">
                            <img
                              class="hoverAvatar"
                              :src="hoverUserInfo.avatar"
                              alt=""
                            />
                          </el-col>
                          <el-col :span="20">
                            <div class="hoverUserName">
                              {{ hoverUserInfo.userName }}
                            </div>

                            <div class="hoverDes">
                              {{ hoverUserInfo.introduction }}
                            </div>
                            <el-button
                              v-if="
                                !userInfo.id ||
                                hoverUserInfo.userId != userInfo.id
                              "
                              @click="focusUser(hoverUserInfo.userId)"
                              class="hoverConcern"
                              size="small"
                              round
                              :type="hoverUserInfo.concern ? 'info' : 'primary'"
                              >{{ hoverUserInfo.concern ? "已关注" : "关注" }}
                            </el-button>
                          </el-col>
                        </el-row>
                      </div>
                      <div slot="reference">
                        <img
                          :src="comment.avatar"
                          class="userAvatar"
                          @click.stop="jumpUserPage(comment.userId)"
                        />
                      </div>
                    </el-popover>
                  </router-link>
                  <div
                    style="
                      vertical-align: top;
                      font-size: 12px;
                      margin-left: 6px;
                      display: inline-block;
                      line-height: 20px;
                    "
                  >
                    {{ comment.username }}
                  </div>
                </div>
              </div>
              <div style="padding-left: 60px; transform: translateY(-26px)">
                <div
                  class="team-cell-desc"
                  v-html="comment.visibility ? comment.content : `该评论已删除`"
                ></div>
                <div class="team-cell-info">
                  <div class="comment-user">
                    <div style="font-size: 12px; margin-left: 0">
                      {{ comment.createTime }}
                    </div>

                    <div
                      class="comment-number"
                      @click="likeComments(comment.id)"
                    >
                      <i
                        class="iconfont icon-dianzan"
                        style="font-size: 14px"
                      ></i
                      >{{ comment.likeNums }}
                    </div>
                    <div class="comment-number" @click="showComment(comment)">
                      回复
                    </div>
                  </div>
                </div>
                <div
                  class="replys"
                  v-if="comment.showComment"
                  style="margin-top: 12px"
                >
                  <el-input
                    v-model="comment.commentValue"
                    :placeholder="`评论${comment.username}...`"
                  >
                    <template slot="append">
                      <emotions
                        @chooseEmotion="
                          (emotion) =>
                            handleChildCommentEmotion(emotion, comment)
                        "
                      ></emotions>
                      <el-button
                        style="
                          margin-left: 4px;
                          border-left: 1px solid #dcdfe6;
                          border-radius: 0px;
                        "
                        @click="publishChildComment(comment)"
                        >评论</el-button
                      >
                    </template>
                  </el-input>
                </div>

                <div v-if="comment.children" class="secondCommentList">
                  <div
                    v-for="(childsComment, index) in comment.children.showAll ||
                    comment.children.total <= 3
                      ? comment.children.records
                      : comment.children.records.slice(0, 3)"
                    :key="index"
                    class="childComment"
                  >
                    <router-link
                      :to="`/userPage?userId=${childsComment.userId}`"
                    >
                      <img :src="childsComment.avatar" class="avatar" alt="" />
                    </router-link>
                    <div
                      v-if="
                        !childsComment.toAvatar || !childsComment.toUsername
                      "
                      style="display: inline-block; width: calc(100% - 40px)"
                    >
                      {{ childsComment.username }}:
                      <span class="commentContent">
                        {{
                          childsComment.visibility
                            ? childsComment.content
                            : "该评论已删除"
                        }}
                      </span>
                    </div>
                    <div
                      v-else
                      style="display: inline-block; width: calc(100% - 40px)"
                    >
                      {{ childsComment.username }}
                      <i class="el-icon-caret-right"></i>
                      {{ childsComment.toUsername }}:
                      <span class="commentContent">
                        {{
                          childsComment.visibility
                            ? childsComment.content
                            : "该评论已删除"
                        }}
                      </span>
                    </div>

                    <div class="team-cell-info">
                      <div class="comment-user">
                        <span style="font-size: 12px">{{
                          childsComment.createTime
                        }}</span>
                        <div
                          class="comment-number"
                          @click="likeChildsComments(comment, childsComment)"
                        >
                          <i
                            class="iconfont icon-dianzan"
                            style="font-size: 14px"
                          ></i
                          >{{ childsComment.likeNums }}
                        </div>
                        <div
                          class="comment-number"
                          @click="showComment(childsComment)"
                        >
                          回复
                        </div>
                      </div>
                    </div>
                    <div
                      class="replys"
                      v-if="childsComment.showComment"
                      style="margin-top: 12px"
                    >
                      <el-input
                        v-model="childsComment.commentValue"
                        :placeholder="`回复${childsComment.username}...`"
                      >
                        <template slot="append">
                          <emotions
                            @chooseEmotion="
                              (emotion) =>
                                handleChildCommentEmotion(
                                  emotion,
                                  childsComment
                                )
                            "
                          ></emotions>
                          <el-button
                            style="
                              margin-left: 4px;
                              border-left: 1px solid #dcdfe6;
                              border-radius: 0px;
                            "
                            @click="
                              publishSecondChildComment(comment, childsComment)
                            "
                            >回复</el-button
                          >
                        </template>
                      </el-input>
                    </div>
                  </div>
                  <div
                    v-if="
                      comment.children.total > 3 && !comment.children.showAll
                    "
                    style="padding-left: 12px"
                  >
                    共{{ comment.children.total }}条回复，<span
                      style="color: #00a1d6; cursor: pointer"
                      @click="
                        comment.children.showAll = true;
                        $forceUpdate();
                      "
                      >点击查看</span
                    >
                  </div>
                  <div
                    style="text-align: left; padding: 12px"
                    v-if="
                      comment.children.total > 10 && comment.children.showAll
                    "
                  >
                    <el-pagination
                      style="padding: 0"
                      prev-text="上一页"
                      next-text="下一页"
                      :current-page.sync="comment.children.current"
                      :total="comment.children.total"
                      layout="prev, pager, next"
                      @current-change="(page) => getChildComments(comment)"
                    >
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="commentsArray.length == 0" class="noComments">暂无评论</div>
      </div>
    </div>
    <div class="pagination-block" v-if="commentsArray.length > 0">
      <el-pagination
        :current-page.sync="currentPage"
        :total="total"
        layout="prev, pager, next"
        @current-change="(page) => getComments(page, true)"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getComments,
  publishComment,
  getChildComments,
} from "@/service/comments";
import emotions from "@/utils/emotions/index";
import { mapState } from "vuex";
import Prism from "prismjs";
import { focusObject } from "@/service/trends";
import { getUserDetail } from "@/service/user";
export default {
  name: "Comments",
  props: {
    type: {
      type: Number,
      required: true,
    },
    commentTotal: {
      type: Number,
      required: true,
      default: 0,
    },
    hiddenTitle: {
      type: Boolean,
      default: false,
    },
    customContainerStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { emotions },

  data() {
    return {
      commentsArray: [],
      currentPage: 1,
      total: 0,
      hoverUserInfo: {},
    };
  },
  mounted() {
    if (this.$route.query.commentId && this.$route.query.commentPage) {
      this.currentPage = Number(this.$route.query.commentPage);
      getComments(
        this.$route.query.id,
        this.$route.query.commentPage,
        this.type
      ).then((res) => {
        if (res.success) {
          this.commentsArray = res.result.records;
          this.$nextTick(() => {
            Prism.highlightAll();
          });
          this.total = res.result.total;
          setTimeout(() => {
            this.$nextTick(() => {
              this.$refs[
                `commentRef-${this.$route.query.commentId}`
              ][0].scrollIntoView();
            });
          }, 500);
        }
      });
    } else if (this.$route.query.id) {
      getComments(this.$route.query.id, 1, this.type).then((res) => {
        if (res.success) {
          this.commentsArray = res.result.records;
          this.$nextTick(() => {
            Prism.highlightAll();
          });
          this.total = res.result.total;
        }
      });
    } else {
      if (this.$route.path == "/liveRoom") {
        getComments(1, 1, this.type).then((res) => {
          if (res.success) {
            this.commentsArray = res.result.records;
            this.$nextTick(() => {
              Prism.highlightAll();
            });
            this.total = res.result.total;
          }
        });
      }
    }

    this.$bus.$on("updateCommentList", (e) => {
      if (e == "update") {
        this.getComments(this.currentPage);
      }
    });
  },
  methods: {
    getComments(page, mark, callback) {
      if (this.$route.query.id) {
        getComments(this.$route.query.id, page ? page : 1, this.type).then(
          (res) => {
            if (res.success) {
              this.commentsArray = res.result.records;
              this.$nextTick(() => {
                Prism.highlightAll();
              });
              this.total = res.result.total;
              if (mark) {
                this.$refs.commentsBelow.scrollIntoView();
              }
              if (callback) {
                callback();
              }
            }
          }
        );
      } else {
        if (this.$route.path == "/liveRoom") {
          getComments(1, page ? page : 1, this.type).then((res) => {
            if (res.success) {
              this.commentsArray = res.result.records;
              this.$nextTick(() => {
                Prism.highlightAll();
              });
              this.total = res.result.total;
              if (mark) {
                this.$refs.commentsBelow.scrollIntoView();
              }
              if (callback) {
                callback();
              }
            }
          });
        }
      }
    },
    likeComments(commentId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      let data;
      if (this.$route.query.id) {
        data = {
          type: false,
          parentId: commentId,
          superId: this.$route.query.id,
          superType: this.type,
        };
      } else {
        if (this.$route.path == "/liveRoom") {
          data = {
            type: false,
            parentId: commentId,
            superId: 1,
            superType: this.type,
          };
        }
      }

      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.closeAll();
          this.$message.success("点赞成功！");
          this.getComments(this.currentPage);
        } else {
          this.$message.closeAll();
          this.$message.info("已点过赞");
        }
      });
    },
    likeChildsComments(parentComment, childComment) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      let data;
      if (this.$route.query.id) {
        data = {
          superType: this.type,
          superId: this.$route.query.id,
          type: false,
          parentId: childComment.id,
          commentId: parentComment.id,
        };
      } else {
        if (this.$route.path == "/liveRoom") {
          data = {
            superType: this.type,
            superId: 1,
            type: false,
            parentId: childComment.id,
            commentId: parentComment.id,
          };
        }
      }

      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.closeAll();
          this.$message.success("点赞成功！");
          this.getChildComments(parentComment);
        } else {
          this.$message.closeAll();
          this.$message.info("已点过赞");
        }
      });
    },
    showComment(comment) {
      comment.showComment = !comment.showComment;
      this.$forceUpdate();
    },
    publishChildComment(comment) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      if (!comment.commentValue || comment.commentValue == "") {
        return this.$message.error("请输入评论内容！");
      }
      let data;
      if (this.$route.query.id) {
        data = {
          content: comment.commentValue,
          superType: this.type,
          type: true,
          superId: this.$route.query.id,
          parentId: comment.id,
          commentId: comment.id,
        };
      } else {
        if (this.$route.path == "/liveRoom") {
          data = {
            content: comment.commentValue,
            superType: this.type,
            type: true,
            superId: 1,
            parentId: comment.id,
            commentId: comment.id,
          };
        }
      }

      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.success("评论成功！");
          this.getComments(this.currentPage);
          comment.commentValue = "";
          this.$forceUpdate();
        }
      });
    },
    async publishSecondChildComment(parentComment, childComment) {
      if (childComment.commentValue == "") {
        return this.$message.error("请输入内容！");
      }
      let data;
      if (this.$route.query.id) {
        data = {
          content: childComment.commentValue,
          superType: this.type,
          type: true,
          superId: this.$route.query.id,
          parentId: childComment.id,
          commentId: parentComment.id,
        };
      } else {
        if (this.$route.path == "/liveRoom") {
          data = {
            content: childComment.commentValue,
            superType: this.type,
            type: true,
            superId: 1,
            parentId: childComment.id,
            commentId: parentComment.id,
          };
        }
      }

      publishComment(data).then(async (res) => {
        if (res.success) {
          this.$message.success("评论成功！");
          this.getChildComments(parentComment);
          childComment.commentValue = "";
          this.$forceUpdate();
        }
      });
    },
    handleChildCommentEmotion(emotion, comment) {
      if (comment.commentValue) {
        comment.commentValue = comment.commentValue + emotion;
      } else {
        comment.commentValue = "" + emotion;
      }
      this.$forceUpdate();
    },
    getChildComments(parentComment) {
      getChildComments(parentComment.id, parentComment.children.current).then(
        (res) => {
          if (res.success) {
            parentComment.children.records = res.result.records;
            parentComment.children.total = res.result.total;
            parentComment.children.showAll = true;
            this.$forceUpdate();
            this.$refs[`commentRef-${parentComment.id}`][0].scrollIntoView();
          }
        }
      );
    },
    jumpUserPage(userId) {
      this.$router.push(`/userPage?userId=${userId}`);
    },
    getHoverUserInfo(userId) {
      getUserDetail(userId).then((res) => {
        if (res.success) {
          this.hoverUserInfo = res.result;
        }
      });
    },
    focusUser(id) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      focusObject({
        superId: id,
        type: 0,
      }).then((res) => {
        if (res.success) {
          this.getHoverUserInfo(id);
          this.$message.closeAll();
          this.$message.success("操作成功!");
        }
      });
    },
  },
  watch: {
    $route() {
      if (this.$route.query.commentId && this.$route.query.commentPage) {
        this.currentPage = Number(this.$route.query.commentPage);
        let id;
        if (this.$route.query.id) {
          id = this.$route.query.id;
        } else {
          if (this.$route.path == "/liveRoom") {
            id = 1;
          }
        }
        getComments(id, this.currentPage, this.type).then((res) => {
          if (res.success) {
            this.commentsArray = res.result.records;
            this.$nextTick(() => {
              Prism.highlightAll();
            });
            this.total = res.result.total;
            setTimeout(() => {
              this.$nextTick(() => {
                this.$refs[
                  `commentRef-${this.$route.query.commentId}`
                ][0].scrollIntoView();
              });
            }, 500);
          }
        });
      } else {
        this.getComments();
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style scoped lang="scss">
.comment-user div {
  font-size: 12px;
  line-height: 21px;
  margin-left: 12px;
  cursor: pointer;
  display: inline-block;
}
.team-cell-title {
  color: #34495e;
  line-height: 36px;
  font-size: 16px;
  margin-bottom: 4px;
}
.commentArea,
.commentsBelow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 30px 48px;
  margin: 12px 0;
}
.userInfo.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.noComments {
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
}
.team-cell-desc {
  font-size: 14px;
  color: #222;
  line-height: 20px;
  margin-bottom: 4px;
}

.team-cell-desc .el-link {
  margin-left: 30px;
}

.team-cell .el-link {
  color: #fa6400;
}

.team-cell__bd {
  position: relative;
  margin-bottom: -26px;
}

.team-cell-info .comment-user {
  color: #34495e;
}
.childComment .team-cell-info .comment-user {
  color: #34495e;
  padding-left: 30px;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 15px;
  cursor: pointer;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}

.team-cell__footer {
  padding: 15px 20px 0;
}

.team-cell__ed {
  float: left;
}

.team-cell__block {
  position: relative;
  margin-left: 44px;
}

.team-cell__bts {
  text-align: right;
  padding: 10px 0;
}

.team-cell__bts .btn {
  font-size: 14px;
  border-radius: 2px;
  width: 64px;
  line-height: 28px;
  border-color: transparent;
  margin-left: 10px;
}
.panel-title {
  position: relative;
  font-weight: 500;
  color: #34495e;
  font-size: 14px;
  padding-left: 11px;
  line-height: 20px;
}

.panel-title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
  left: 0;
  top: 0;
}
.team-cell-info .comment-user {
  color: #34495e;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 25px;
  font-size: 12px;
  display: inline-block;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}
.team-cell:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.team-cell {
  padding: 24px 0;
}
.secondCommentList {
  width: 95%;
  margin: 12px 0;
  margin-bottom: 0;
}
.childComment {
  padding: 6px;
  line-height: 24px;
  .avatar {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border-radius: 50%;
    vertical-align: top;
  }
  .commentContent {
    color: #222;
    margin-top: 4px;
  }
}
.pagination-block {
  text-align: center;
  padding: 20px 0;
}
::v-deep .w-e-content-container pre[class*="language-"] {
  white-space: normal !important;
}
.userAvatar {
  width: 48px;
  height: 48px;
  vertical-align: top;
  margin-right: 6px;
  border-radius: 50%;
  display: inline-block;
}
::v-deep .el-popover__reference {
  display: inline-block;
}
.hoverUserInfo {
  padding: 14px;
  .hoverAvatar {
    width: 42px;
    height: 42px;
    vertical-align: top;
    border-radius: 50%;
  }
  .hoverUserName {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 21px;
  }
  .hoverDes {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #828282;
    line-height: 16px;
    margin-top: 6px;
  }
  .hoverConcern {
    margin-top: 20px;
    background: rgba(0, 117, 246, 0.2);
    width: 144px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    &.el-button--info {
      background: rgba(52, 73, 94, 0.2);
      border: none;
      color: #34495e;
    }
  }
}
</style>