// 动态
import request from '../utils/request';

// 获取动态主页信息
export async function getTrendsStatistics() {
    try {
        return await request({
            url: `/posts/statics`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

//关注人或公司
export async function focusObject(data) {
    try {
        return await request({
            url: `/concern`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// 获取动态列表
export async function getTrendsList(params) {
    try {
        return await request({
            url: `/posts`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取工作列表
export async function getFindJobList(params) {
    try {
        return await request({
            url: `/posts/northAmericanJob`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }

}

// 发布动态
export async function publishTrends(data) {
    try {
        return await request({
            url: `/posts/notice/issueDynamic`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}


//点赞 
export async function likeTrends(data) {
    try {
        return await request({
            url: `/posts/notice/issueDynamic`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}


// 浏览
export async function browseTrends(id) {
    try {
        return await request({
            url: `/browse/${id}`,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}