// 评论区
import request from '../utils/request';

// 获取评论
export async function getComments(parentId, current, type) {
    try {
        return await request({
            url: `/publicComment/comment/${parentId}?current=${current?current:1}&type=${type}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}

// 发布评论
export async function publishComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}
// 获取子级评论
export async function getChildComments(commentId, page) {
    try {
        return await request({
            url: `/publicComment/commentChildren/${commentId}?current=${page? page :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 获取动态评论
export async function getTrendsComments(parentId, current, type) {
    try {
        return await request({
            url: `/publicComment/noticeComment/${parentId}?current=${current}&type=${type}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 获取动态详情
export async function getTrendDetail(parentId, type) {
    try {
        return await request({
            url: `/publicComment/commentLike/${parentId}?type=${type}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}