<template>
  <div style="display: inline-block; margin-right: 6px">
    <el-popover placement="top" width="400" trigger="click" v-model="visible">
      <div class="EmoticonList">
        <div
          class="PicItem"
          v-for="(item, i) in faceList"
          @click="ClickEmoticon(item)"
          :key="i"
        >
          {{ item }}
        </div>
      </div>
      <el-button v-if="isIcon" style="padding: 4px" slot="reference">
        {{ faceList[0] }}
      </el-button>
      <i v-else style="padding: 4px; font-style: inherit" slot="reference">
        {{ faceList[0] }}
      </i>
    </el-popover>
  </div>
</template>
<script>
import emotionData from "./emotion.json";
export default {
  name: "emotions",
  props: {
    isIcon: { type: Boolean, default: true },
  },
  data() {
    return {
      faceList: [],
      visible: false,
    };
  },
  mounted() {
    this.loadEmojis();
  },
  methods: {
    ClickEmoticon(item) {
      this.visible = false;
      this.$emit("chooseEmotion", item);
    },
    loadEmojis() {
      for (let i in emotionData) {
        this.faceList.push(emotionData[i].char);
      }
    },
  },
};
</script>
<style scoped>
.EmoticonList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.PicItem {
  margin: 4px;
  cursor: pointer;
  font-size: 22px;
}
</style>